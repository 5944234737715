import React, { Component } from 'react';
import {v4 as uuidv4} from 'uuid';
import * as Styles from '../styles/news.module.scss';

class News extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            num_of_pages: 0,
            content: []
        }
        this.pageChanger = this.pageChanger.bind(this);
        this.fetcher = this.fetcher.bind(this);
        this.pageChanger = this.pageChanger.bind(this);
    }

    componentDidMount() {
        const totop = document.getElementById("totop");
        window.addEventListener("scroll", () => {
            if(window.pageYOffset > 100){
                totop.style.display = "flex";
                totop.style.opacity = "1";
            }
            else{
                totop.style.opacity = "0";
            }
        })

        fetch('https://script.google.com/macros/s/AKfycbz5zqTMkJbP0fGl2SDuaPQclNzFauxUnLBmlxIZH-OaSr5exECphhnzMif6Q8NJJ39ATg/exec?s=0').then(response => {
            if (response.ok) {
                console.log("Received data successfully");
                return response.json();
            }
            else {
                throw new Error("Problem fetching initial data");
            }
        }).then(data => {
            this.setState({ num_of_pages: Math.ceil(data.rows/10) });
            this.setState({ content: this.state.content.concat([data.content.reverse()]) });
            console.log(this.state.content);
        }).catch(error => console.error(error));
    }

    fetcher(page) {
        fetch(`https://script.google.com/macros/s/AKfycbz5zqTMkJbP0fGl2SDuaPQclNzFauxUnLBmlxIZH-OaSr5exECphhnzMif6Q8NJJ39ATg/exec?s=${page*10}`).then(response => {
            if (response.ok) {
                console.log("Received data successfully");
                return response.json();
            }
            else {
                throw new Error("Problem fetching initial data");
            }
        }).then(data => {
            this.setState({ content: this.state.content.concat([data.content.reverse()]) });
            console.log(this.state.content);
        }).catch(error => console.error(error));
    }

    pageChanger(page) {
        this.setState({page: page});
        this.fetcher(page);
    }

    render() {
        if (this.state.content[this.state.page] === undefined){
            return (<div className={Styles.main}><div ><div className="spin"></div></div></div>);
        }
        return (
            <section className={Styles.main}>
                <div className={Styles.contents}>
                    {this.state.content[this.state.page].map( data => (
                        <div key={uuidv4()} className={Styles.content}>
                            <h1>{data[1]}</h1>
                            <br/>
                            <p>{data[2]}</p>
                            <br/>
                            <a href={data[0]} target="_blank" rel="noreferrer">{data[0]}</a>
                            
                        </div>
                    ))}
                </div>
                <div className={Styles.pagination}>
                    {(!this.state.page === 0) && <button onClick={() => this.pageChanger(0)}>
                        First
                    </button>}
                    {(!this.state.page === 0) && <button onClick={() => this.pageChanger(this.state.page-1)}>
                        Prev
                    </button>}
                    {(this.state.page > 1) && <button onClick={() => this.pageChanger(this.state.page-2)}>
                        {this.state.page-1}
                    </button>}
                    {(!this.state.page === 0) && <button onClick={() => this.pageChanger(this.state.page-1)}>
                        {this.state.page}
                    </button>}
                    <button className={Styles.selected} onClick={() => this.pageChanger(this.state.page)}>
                        {this.state.page+1}
                    </button>
                    {(this.state.num_of_pages >= this.state.page+2) && <button onClick={() => this.pageChanger(this.state.page+1)}>
                        {this.state.page+2}
                    </button>}
                    {(this.state.num_of_pages > this.state.page+2) && <button onClick={() => this.pageChanger(this.state.page+2)}>
                        {this.state.page+2}
                    </button>}
                    {(this.state.num_of_pages > this.state.page+1) && <button onClick={() => this.pageChanger(this.state.page+1)}>
                        Next
                    </button>}
                    {(this.state.num_of_pages > this.state.page+1) && <button onClick={() => this.pageChanger(this.state.num_of_pages-1)}>
                        Last
                    </button>}
                </div>
            </section>
        );
    }
}


export default News;